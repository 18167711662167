import { useEffect, useState } from "react";
import { httpsCallable, getFunctions } from "firebase/functions";
import { firebaseApp } from "../../context/firebaseConfig";
import { getDatabase, ref, onValue } from "firebase/database";

const translations = {
  removeArticle: {
    NL: "Artikel bijwerken",
    FR: "Mettre à jour l'article",
    EN: "Update article",
  },
  loading: {
    NL: "Laden...",
    FR: "Chargement...",
    EN: "Loading...",
  },
  searchPlaceholder: {
    NL: "Zoeken...",
    FR: "Recherche...",
    EN: "Search...",
  },
  delete: {
    NL: "VERWIJDEREN",
    FR: "SUPPRIMER",
    EN: "DELETE",
  },
  removeConfirmation: {
    NL: "Weet je zeker dat je dit item wilt verwijderen?",
    FR: "Voulez-vous vraiment supprimer cet article?",
    EN: "Are you sure you want to remove this item?",
  },
  cancel: {
    NL: "Annuleren",
    FR: "Annuler",
    EN: "Cancel",
  },
  available: {
    NL: "Beschikbaar",
    FR: "Disponible",
    EN: "Available",
  },
  reserved: {
    NL: "Gereserveerd",
    FR: "Réservé",
    EN: "Reserved",
  },
  sold: {
    NL: "Verkocht",
    FR: "Vendu",
    EN: "Sold",
  },
  update: {
    NL: "Bijwerken",
    FR: "Actualiser",
    EN: "Update",
  },
  articleInfo: {
    NL: {
      name: "Naam",
      id: "ID",
      moreInfo: "Meer info",
      originalValue: "Originele waarde",
      newPrice: "Nieuwe prijs",
      status: "Status",
      thumbURL: "Thumb Afbeeldings URL",
      imageURL: "Volledige Afbeeldings URL",
      copy: "Kopieer",
    },
    FR: {
      name: "Nom",
      id: "ID",
      moreInfo: "Plus d'infos",
      originalValue: "Valeur d'origine",
      newPrice: "Nouveau prix",
      status: "Statut",
      thumbURL: "URL de l'image miniature",
      imageURL: "URL de l'image complète",
      copy: "Copier",
    },
    EN: {
      name: "Name",
      id: "ID",
      moreInfo: "More info",
      originalValue: "Original value",
      newPrice: "New price",
      status: "Status",
      thumbURL: "Thumb Image URL",
      imageURL: "Full Image URL",
      copy: "Copy",
    },
  },
};

const functions = getFunctions(firebaseApp, "europe-west1");

export default function UpdateArticle({ language }) {
  const [articles, setArticles] = useState([]);
  const [unlockedArticleID, setUnlockedArticleID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [editableArticle, setEditableArticle] = useState({});

  useEffect(() => {
    const db = getDatabase(firebaseApp);
    const articlesRef = ref(db, "articles");

    const handleData = (snapshot) => {
      const articles = snapshot.val();
      const articlesArray = Object.values(articles || {});
      setArticles(articlesArray);
      setFilteredArticles(articlesArray);
      setLoading(false);
    };

    onValue(articlesRef, handleData, (error) => {
      console.error("Error fetching articles:", error);
      setLoading(false);
    });

    return () => {};
  }, []);

  const updateArticleField = (field, value) => {
    setEditableArticle((prev) => ({ ...prev, [field]: value }));
  };

  const startEditingArticle = (article) => {
    if (article.id !== unlockedArticleID) {
      setEditableArticle(article);
      setUnlockedArticleID(article.id);
    }
  };

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);

    const filtered = articles.filter((article) => {
      const searchTerm = inputValue.toLowerCase();
      return (
        article.name.toLowerCase().includes(searchTerm) ||
        article.tilquinID.toLowerCase().includes(searchTerm) ||
        article.id.toLowerCase().includes(searchTerm) ||
        article.status.toLowerCase().includes(searchTerm) ||
        article.moreInfo.toLowerCase().includes(searchTerm)
      );
    });

    setFilteredArticles(filtered);
  };

  const handleUpdate = async (articleId) => {
    try {
      const updateArticleFunction = httpsCallable(functions, "updateArticle");
      const response = await updateArticleFunction({ id: articleId, ...editableArticle });
      console.log(response.data.result); // Log the result for now
    } catch (error) {
      console.error("Error updating article:", error);
    }
  };

  return (
    <div className="w-full max-w-lg mx-auto">
      <h1 className="text-center text-2xl mb-5">{translations.removeArticle[language]}</h1>

      {loading ? (
        <p className="text-center">{translations.loading[language]}</p>
      ) : (
        <div className="flex flex-col space-y-8 px-4 min-h-screen">
          <div className="flex justify-end mb-4">
            <input
              type="text"
              value={searchInput}
              onChange={handleSearchInputChange}
              placeholder={translations.searchPlaceholder[language]}
              className="p-2 border border-gray-300 rounded-md w-full"
            />
          </div>
          {filteredArticles.map((article) => (
            <div
              key={article.id}
              className={`${
                unlockedArticleID === article.id ? "bg-white" : "bg-gray-300"
              } rounded-lg shadow-md flex flex-row items-stretch h-[500px] cursor-pointer`}
              onClick={() => startEditingArticle(article)}
            >
              <div className="flex flex-col justify-between w-3/4">
                <div className="text-sm p-5 overflow-hidden">
                  <p className="overflow-hidden whitespace-nowrap py-2">{translations.articleInfo[language].id}</p>
                  <p className="font-bold">{article.tilquinID}</p>
                  <p className="overflow-hidden whitespace-nowrap py-2">{translations.articleInfo[language].name}</p>
                  <input
                    type="text"
                    value={unlockedArticleID === article.id ? editableArticle.name : article.name}
                    onChange={(e) => updateArticleField("name", e.target.value)}
                    placeholder={translations.articleInfo[language].name}
                    disabled={unlockedArticleID !== article.id}
                    className="p-2 border border-gray-300 rounded-md w-full mb-2"
                  />
                  <p className="overflow-hidden whitespace-nowrap py-2">
                    {translations.articleInfo[language].moreInfo}
                  </p>
                  <input
                    type="text"
                    value={unlockedArticleID === article.id ? editableArticle.moreInfo : article.moreInfo}
                    onChange={(e) => updateArticleField("moreInfo", e.target.value)}
                    placeholder={translations.articleInfo[language].moreInfo}
                    disabled={unlockedArticleID !== article.id}
                    className="p-2 border border-gray-300 rounded-md w-full mb-2"
                  />
                  <p className="overflow-hidden whitespace-nowrap py-2">
                    {translations.articleInfo[language].originalValue}
                  </p>
                  <input
                    type="number"
                    value={unlockedArticleID === article.id ? editableArticle.value : article.value}
                    onChange={(e) => updateArticleField("value", parseFloat(e.target.value))}
                    placeholder={translations.articleInfo[language].value}
                    disabled={unlockedArticleID !== article.id}
                    className="p-2 border border-gray-300 rounded-md w-full mb-2"
                  />
                  <p className="overflow-hidden whitespace-nowrap py-2">
                    {translations.articleInfo[language].newPrice}
                  </p>
                  <input
                    type="number"
                    value={unlockedArticleID === article.id ? editableArticle.newPrice : article.newPrice}
                    onChange={(e) => updateArticleField("newPrice", parseFloat(e.target.value))}
                    placeholder={translations.articleInfo[language].newPrice}
                    disabled={unlockedArticleID !== article.id}
                    className="p-2 border border-gray-300 rounded-md w-full mb-2"
                  />
                  <p className="overflow-hidden whitespace-nowrap py-2">{translations.articleInfo[language].status}</p>
                  <select
                    value={unlockedArticleID === article.id ? editableArticle.status : article.status}
                    onChange={(e) => {
                      updateArticleField("status", e.target.value);
                    }}
                    disabled={unlockedArticleID !== article.id}
                    className="p-2 border border-gray-300 rounded-md w-full mb-2"
                  >
                    <option value="available">{translations.available[language]}</option>
                    <option value="reserved">{translations.reserved[language]}</option>
                    <option value="sold">{translations.sold[language]}</option>
                  </select>
                </div>
              </div>

              <div className="flex flex-col h-full w-1/4">
                {" "}
                <img
                  src={article.thumbURL}
                  alt={article.name}
                  className="rounded-tr-lg object-cover flex-1 h-1/3 w-full"
                />
                <button
                  onClick={() => handleUpdate(article.id)}
                  disabled={unlockedArticleID !== article.id}
                  className={`${
                    unlockedArticleID === article.id ? "bg-gray-700 hover:bg-gray-600" : "bg-gray-300"
                  } text-white px-4 py-2 w-full rounded-br-lg h-2/3`}
                >
                  {translations.update[language]}
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
