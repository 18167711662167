import Welcome from "./Welcome";
import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from "./context/firebaseConfig";

import Login from "./components/Login";
import Admin from "./pages/admin/Admin";
import Announcement from "./pages/Announcement";
import Contact from "./pages/Contact";
// import Sale from "./pages/sale/Sale";
// import ShoppingCart from "./pages/shoppingCart/ShoppingCart";
import Footer from "./components/Footer";

export default function App() {
  const [page, setPage] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState("NL");
  const [basket, setBasket] = useState([]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const pageParam = urlParams.get("page");

    setPage(pageParam ? pageParam.toLowerCase() : null);

    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        setUser(user);
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    const savedBasket = localStorage.getItem("basket");
    const lastUpdated = localStorage.getItem("basketUpdated");
    const twentyFourHours = 24 * 60 * 60 * 1000;

    if (savedBasket && lastUpdated) {
      try {
        const timeDifference = Math.abs(new Date().getTime() - parseInt(lastUpdated, 10));

        if (timeDifference < twentyFourHours) {
          setBasket(JSON.parse(savedBasket));
          return;
        }
      } catch (error) {
        console.error("Error parsing savedBasket or lastUpdated:", error);
      }
    }

    localStorage.removeItem("basket");
    localStorage.removeItem("basketUpdated");
    setBasket([]);
  }, []);

  const renderComponent = () => {
    switch (page) {
      case "admin":
        if (!user) {
          return <Login setPage={setPage} language={language} />;
        }
        return (
          // <Admin
          //   language={language}
          //   setLanguage={setLanguage}
          //   page={page}
          //   setPage={setPage}
          //   basket={basket}
          //   setBasket={setBasket}
          // />
          <Announcement
            language={language}
            setLanguage={setLanguage}
            page={page}
            setPage={setPage}
            basket={basket}
            setBasket={setBasket}
          />
        );
      case "welcome":
        return (
          <Welcome
            language={language}
            setLanguage={setLanguage}
            page={page}
            setPage={setPage}
            basket={basket}
            setBasket={setBasket}
          />
        );
      case "announcement":
        return (
          <Announcement
            language={language}
            setLanguage={setLanguage}
            page={page}
            setPage={setPage}
            basket={basket}
            setBasket={setBasket}
          />
        );
      case "contact":
        return (
          // <Contact
          //   language={language}
          //   setLanguage={setLanguage}
          //   page={page}
          //   setPage={setPage}
          //   basket={basket}
          //   setBasket={setBasket}
          // />
          <Announcement
            language={language}
            setLanguage={setLanguage}
            page={page}
            setPage={setPage}
            basket={basket}
            setBasket={setBasket}
          />
        );
      case "sale":
        return (
          // <Sale
          //   language={language}
          //   setLanguage={setLanguage}
          //   page={page}
          //   setPage={setPage}
          //   basket={basket}
          //   setBasket={setBasket}
          // />
          <Announcement
            language={language}
            setLanguage={setLanguage}
            page={page}
            setPage={setPage}
            basket={basket}
            setBasket={setBasket}
          />
        );
      case "shoppingcart":
        return (
          // <ShoppingCart
          //   language={language}
          //   setLanguage={setLanguage}
          //   page={page}
          //   setPage={setPage}
          //   basket={basket}
          //   setBasket={setBasket}
          // />
          <Announcement
            language={language}
            setLanguage={setLanguage}
            page={page}
            setPage={setPage}
            basket={basket}
            setBasket={setBasket}
          />
        );
      default:
        return (
          <Welcome
            language={language}
            setLanguage={setLanguage}
            page={page}
            setPage={setPage}
            basket={basket}
            setBasket={setBasket}
          />
        );
    }
  };

  if (loading)
    return (
      <p className="fixed inset-0 w-full h-full flex flex-col items-center justify-center font-tilquin">Loading...</p>
    );
  return (
    <div className="font-tilquin">
      {renderComponent()}
      <Footer />
    </div>
  );
}
