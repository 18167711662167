import { useEffect, useState } from "react";
import { httpsCallable, getFunctions } from "firebase/functions";
import { firebaseApp } from "../../context/firebaseConfig";
import { getDatabase, ref, onValue } from "firebase/database";

const translations = {
  removeArticle: {
    NL: "Artikel verwijderen",
    FR: "Retirer l'article",
    EN: "Remove article",
  },
  loading: {
    NL: "Laden...",
    FR: "Chargement...",
    EN: "Loading...",
  },
  searchPlaceholder: {
    NL: "Zoeken...",
    FR: "Recherche...",
    EN: "Search...",
  },
  delete: {
    NL: "VERWIJDER",
    FR: "SUPPRIMER",
    EN: "DELETE",
  },
  removeConfirmation: {
    NL: "Weet je zeker dat je dit item wilt verwijderen?",
    FR: "Voulez-vous vraiment supprimer cet article?",
    EN: "Are you sure you want to remove this item?",
  },
  cancel: {
    NL: "Annuleren",
    FR: "Annuler",
    EN: "Cancel",
  },
  articleInfo: {
    NL: {
      name: "Naam",
      id: "ID",
      moreInfo: "Meer info",
      originalValue: "Originele waarde",
      newPrice: "Nieuwe prijs",
      status: "Status",
      thumbURL: "Thumb Afbeeldings URL",
      imageURL: "Volledige Afbeeldings URL",
      copy: "Kopieer",
    },
    FR: {
      name: "Nom",
      id: "ID",
      moreInfo: "Plus d'infos",
      originalValue: "Valeur d'origine",
      newPrice: "Nouveau prix",
      status: "Statut",
      thumbURL: "URL de l'image miniature",
      imageURL: "URL de l'image complète",
      copy: "Copier",
    },
    EN: {
      name: "Name",
      id: "ID",
      moreInfo: "More info",
      originalValue: "Original value",
      newPrice: "New price",
      status: "Status",
      thumbURL: "Thumb Image URL",
      imageURL: "Full Image URL",
      copy: "Copy",
    },
  },
};

const functions = getFunctions(firebaseApp, "europe-west1");

export default function RemoveArticle({ language }) {
  const [articles, setArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredArticles, setFilteredArticles] = useState([]);

  useEffect(() => {
    const db = getDatabase(firebaseApp);
    const articlesRef = ref(db, "articles");

    const handleData = (snapshot) => {
      const articles = snapshot.val();
      const articlesArray = Object.values(articles || {});
      setArticles(articlesArray);
      setFilteredArticles(articlesArray);
      setLoading(false);
    };

    onValue(articlesRef, handleData, (error) => {
      console.error("Error fetching articles:", error);
      setLoading(false);
    });

    return () => {};
  }, []);

  const handleRemove = (article) => {
    setSelectedArticle(article);
    setShowConfirmation(true);
  };

  const cancelRemoveItem = () => {
    setShowConfirmation(false);
  };

  const confirmRemoveItem = async () => {
    setShowConfirmation(false);

    try {
      const removeArticle = httpsCallable(functions, "removeArticle");
      await removeArticle({ id: selectedArticle.id });
      // const result = await removeArticle({ id: selectedArticle.id });
      // console.log(result.data.message);

      // Remove the deleted article from both articles and filteredArticles state
      setArticles((prevArticles) => prevArticles.filter((article) => article.id !== selectedArticle.id));
      setFilteredArticles((prevFilteredArticles) =>
        prevFilteredArticles.filter((article) => article.id !== selectedArticle.id)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);

    const filtered = articles.filter((article) => {
      const searchTerm = inputValue.toLowerCase();
      return (
        article.name.toLowerCase().includes(searchTerm) ||
        article.tilquinID.toLowerCase().includes(searchTerm) ||
        article.id.toLowerCase().includes(searchTerm) ||
        article.moreInfo.toLowerCase().includes(searchTerm)
      );
    });

    setFilteredArticles(filtered);
  };

  return (
    <div className="w-full max-w-lg mx-auto">
      <h1 className="text-center text-2xl mb-5">{translations.removeArticle[language]}</h1>

      {loading ? (
        <p className="text-center">{translations.loading[language]}</p>
      ) : (
        <div className="flex flex-col space-y-8 px-4 min-h-screen">
          <div className="flex justify-end mb-4">
            <input
              type="text"
              value={searchInput}
              onChange={handleSearchInputChange}
              placeholder={translations.searchPlaceholder[language]}
              className="p-2 border border-gray-300 rounded-md w-full"
            />
          </div>
          {filteredArticles.map((article) => (
            <div key={article.id} className="bg-white rounded-lg shadow-md flex flex-row h-48">
              <div className="flex flex-col justify-between w-3/4">
                <div className="text-sm p-5 overflow-hidden">
                  <p className="overflow-hidden whitespace-nowrap">
                    <strong>{translations.articleInfo[language].name}:</strong> <em>{article.name}</em>
                  </p>
                  <p className="overflow-hidden whitespace-nowrap">
                    <strong>{translations.articleInfo[language].id}:</strong> <em>{article.tilquinID}</em>
                  </p>
                  <p className="overflow-hidden whitespace-nowrap">
                    <strong>{translations.articleInfo[language].moreInfo}:</strong> <em>{article.moreInfo}</em>
                  </p>
                  <p className="overflow-hidden whitespace-nowrap">
                    <strong>{translations.articleInfo[language].originalValue}:</strong> <em>{article.value}</em>
                  </p>
                  <p className="overflow-hidden whitespace-nowrap">
                    <strong>{translations.articleInfo[language].newPrice}:</strong> <em>{article.newPrice}</em>
                  </p>
                  <p className="overflow-hidden whitespace-nowrap">
                    <strong>{translations.articleInfo[language].status}:</strong> <em>{article.status}</em>
                  </p>
                  <p className="overflow-hidden whitespace-nowrap">
                    <strong>
                      {translations.articleInfo[language].thumbURL}
                      {": "}
                    </strong>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(article.thumbURL);
                      }}
                      className="underline"
                    >
                      <em>{translations.articleInfo[language].copy}</em>
                    </button>
                  </p>
                  <p className="overflow-hidden whitespace-nowrap">
                    <strong>
                      {translations.articleInfo[language].imageURL}
                      {": "}
                    </strong>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(article.imageURL);
                      }}
                      className="underline"
                    >
                      <em>{translations.articleInfo[language].copy}</em>
                    </button>
                  </p>
                </div>
              </div>

              <div className="flex flex-col h-full w-1/3">
                {" "}
                <img
                  src={article.thumbURL}
                  alt={article.name}
                  className="rounded-tr-lg object-cover flex-1 h-1/2 w-full"
                />
                <button
                  onClick={() => handleRemove(article)}
                  className="bg-red-700 hover:bg-red-600 text-white px-4 py-2 w-full rounded-br-lg h-1/2"
                >
                  {translations.delete[language]}
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Confirmation screen */}
      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 px-5">
          <div className="bg-white p-5">
            <h1 className="text-xl pb-5">{translations.removeArticle[language]}</h1>
            <p className="mb-3">{translations.removeConfirmation[language]}</p>
            <div className="flex justify-end">
              <button onClick={cancelRemoveItem} className="px-4 py-2 text-gray-700 hover:text-gray-700 uppercase">
                {translations.cancel[language]}
              </button>
              <button
                onClick={confirmRemoveItem}
                className="px-4 py-2 ml-2 bg-red-700 text-white hover:bg-red-600 uppercase"
              >
                {translations.delete[language]}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
