import React from "react";
import gold from "./media/gold.jpg";

export default function Welcome({ page, setPage, language, setLanguage }) {
  return (
    <div className="m-0 p-0 w-full max-w-full block mx-auto font-tilquin">
      <div className="flex flex-col items-center py-4">
        <button className="text-2xl font-bold uppercase">Tilquin</button>
        <span className="text-xs">1860</span>
      </div>

      <img className="w-full max-h-96 mx-auto block border-0 object-contain" src={gold} alt="welcome" />

      {/* <p className="mx-auto block text-red-500 italic text-center mt-5 py-2 px-5">
        WEGENS JAARLIJKSE VAKANTIE ZIJN WIJ GESLOTEN VAN 29/8 t/m 5/9
      </p>
      <p className="mx-auto block text-red-500 italic text-center py-2 px-5">
        DUE TO OUR ANNUAL HOLIDAY, WE ARE CLOSED FROM 29/8 UNTIL 5/9
      </p>
      <p className="mx-auto block text-red-500 italic text-center py-2 px-5">
        EN RAISON DE NOS VACANCES ANNUELLES, NOUS SERONS FERMÉS DU 29/8 AU 5/9
      </p> */}

      <div className="text-center mx-auto my-8 px-5 max-w-xs relative flex space-x-3 justify-between">
        <button
          className="border-b border-transparent hover:text-gray-400 hover:border-gray-400"
          onClick={() => {
            setLanguage("NL");
            setPage("announcement");
          }}
        >
          Nederlands
        </button>
        <button
          className="border-b border-transparent hover:text-gray-400 hover:border-gray-400"
          onClick={() => {
            setLanguage("EN");
            setPage("announcement");
          }}
        >
          English
        </button>
        <button
          className="border-b border-transparent hover:text-gray-400 hover:border-gray-400"
          onClick={() => {
            setLanguage("FR");
            setPage("announcement");
          }}
        >
          Français
        </button>
      </div>

      {/* <div className="mx-auto my-8 max-w-full text-center">
        <a href="mailto:welcome@tilquin.be" className="m-1 block text-gray-800">
          <span className="font-bold px-2">E</span>welcome@tilquin.be
        </a>
        <a href="tel:+3232319905" className="m-1 block text-gray-800">
          <span className="font-bold px-2">T</span>+32 (0)3 231 99 05
        </a>
        <a
          href="https://www.google.be/maps/place/TILQUIN/@51.2159914,4.411429,17z/data=!3m1!4b1!4m5!3m4!1s0x47c3f6fdcbd01c51:0x9c609a12d34891d4!8m2!3d51.2159914!4d4.4136177?hl=en"
          className="m-1 block text-gray-800"
        >
          <span className="font-bold px-2">A</span>Frankrijklei 86, B-2000 Antwerpen
        </a>
      </div> */}
    </div>
  );
}
