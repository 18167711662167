import React, { useState } from "react";
// import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LanguageIcon from "@mui/icons-material/Language";
import MenuIcon from "@mui/icons-material/Menu";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import { firebaseAuth } from "../context/firebaseConfig"; // update the path

const translations = {
  announcement: {
    NL: "Aankondiging",
    FR: "Annonce",
    EN: "Announcement",
  },
  contact: {
    NL: "Contact",
    FR: "Contact",
    EN: "Contact",
  },
  sale: {
    NL: "UITVERKOOP",
    FR: "SOLDES",
    EN: "SALE",
  },
  admin: {
    NL: "Beheerder",
    FR: "Admin",
    EN: "Admin",
  },
};

export default function Navigation({ language, setLanguage, page, setPage, basket }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);

  const menuItems = [
    ["announcement", translations.announcement[language]],
    // ["contact", translations.contact[language]],
    // ["sale", translations.sale[language]],
  ];

  // if (firebaseAuth.currentUser) {
  //   menuItems.push([
  //     "admin",
  //     <div className="flex flex-row space-x-4 justify-center">
  //       <AdminPanelSettingsIcon />
  //       {translations.admin[language]}
  //     </div>,
  //   ]);
  // }

  const renderPageButtons = menuItems.map(([pg, label]) => (
    <button
      key={pg}
      className={`block px-4 py-2 hover:bg-gray-100 w-full text-center ${
        page === pg ? "underline underline-offset-4" : ""
      }`}
      onClick={() => {
        setPage(pg);
        setMenuOpen(false);
      }}
    >
      {pg === "sale" ? <strong>{label}</strong> : label}
    </button>
  ));

  return (
    <nav className="flex flex-row justify-between w-full items-end p-10 relative">
      {menuOpen && (
        <div className="fixed inset-0 w-full h-full flex flex-col items-center justify-center z-10 bg-white space-y-5">
          {renderPageButtons}
        </div>
      )}

      <div className="flex flex-col items-center">
        <button className="text-2xl font-bold uppercase" onClick={() => setPage(null)}>
          Tilquin
        </button>
        <span className="text-xs">1860</span>
      </div>

      <div className="hidden sm:flex sm:flex-row">{renderPageButtons}</div>

      <div className={`relative flex flex-row ${languageMenuOpen ? "" : "space-x-4"}`}>
        <button
          className="font-medium text-gray-800 hover:text-gray-600 focus:outline-none sm:hidden"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <MenuIcon />
        </button>
        {/* <button className="text-gray-800 relative" onClick={() => setPage("shoppingcart")}>
          <ShoppingCartIcon />
          <span className="text-[10px] rounded-full bg-slate-500 text-white absolute w-3 h-3 bottom-0 right-0 flex justify-center items-center">
            {basket?.length || 0}
          </span>
        </button> */}
        <button className="text-gray-800" onClick={() => setLanguageMenuOpen(!languageMenuOpen)}>
          <LanguageIcon />
        </button>
        {languageMenuOpen && (
          <LanguageMenu
            languages={[
              { code: "EN", name: "English" },
              { code: "NL", name: "Nederlands" },
              { code: "FR", name: "Français" },
            ]}
            selectedLanguage={language}
            setLanguage={setLanguage}
            onClose={() => setLanguageMenuOpen(false)}
          />
        )}
      </div>
    </nav>
  );
}

const LanguageMenu = ({ languages, selectedLanguage, setLanguage, onClose }) => {
  return (
    <div className="fixed inset-0 w-full h-full flex flex-col items-center justify-center z-10 bg-white space-y-5">
      {languages.map(({ code, name }) => (
        <button
          key={code}
          className={`block px-4 py-2 hover:bg-gray-100 ${
            code === selectedLanguage ? "underline underline-offset-4" : ""
          }`}
          onClick={() => {
            setLanguage(code);
            onClose();
          }}
        >
          {name}
        </button>
      ))}
    </div>
  );
};
