import React, { useState } from "react";
import Navigation from "../components/Navigation";
import { database } from "../context/firebaseConfig";
import storeImage from "../media/white.jpg";
import { ref, push } from "firebase/database";
import TextField from "@mui/material/TextField";

const translations = {
  title: {
    NL: "WIJ VERHUIZEN…",
    FR: "NOUS DÉMÉNAGEONS…",
    EN: "WE ARE MOVING…",
  },
  info1: {
    NL: "Eind december 2023 heeft TILQUIN haar winkelruimte, gelegen Frankrijklei 86 te Antwerpen, gesloten.",
    FR: "Fin décembre 2023, TILQUIN a fermé son espace de vente situé au Frankrijklei 86 à Anvers.",
    EN: "By the end of December 2023, TILQUIN closed its retail space located at Frankrijklei 86 in Antwerp.",
  },
  info2: {
    NL: "Wellicht midden 2024 zullen wij U in onze nieuwe omgeving in de buurt van Antwerpen mogen ontvangen, vooreerst zijn belangrijke verbouwingswerken gepland.",
    FR: "Probablement au milieu de 2024, nous pourrons vous accueillir dans notre nouvel environnement près d'Anvers, des travaux de rénovation importants sont prévus en premier lieu.",
    EN: "Likely by mid-2024, we will be able to welcome you in our new environment near Antwerp; initially, significant renovations are planned.",
  },
  info3: {
    NL: "Indien U wenst dat wij U op de hoogte houden, stellen wij U vriendelijk voor uw mail-adres via dit formulier te willen invullen.",
    FR: "Si vous souhaitez que nous vous tenions informé, nous vous invitons aimablement à remplir votre adresse e-mail via ce formulaire.",
    EN: "If you wish to be kept informed, we kindly ask you to fill in your email address via this form.",
  },
  info4: {
    NL: "Helaas heeft de bouw van de nieuwe winkel enige vertraging opgelopen door onvoorziene omstandigheden. Door het bijzonder natte voorjaar konden de werkzaamheden van de aannemer niet zoals gepland beginnen, wat heeft geleid tot een verschuiving van de openingsdatum.",
    FR: "Malheureusement, la construction de la nouvelle boutique a subi quelques retards en raison de circonstances imprévues. Le printemps particulièrement pluvieux a empêché l'entrepreneur de commencer les travaux comme prévu, ce qui a entraîné un report de la date d'ouverture.",
    EN: "Unfortunately, the construction of the new store has encountered some delays due to unforeseen circumstances. The particularly wet spring prevented the contractor from starting the work as planned, leading to a postponement of the opening date.",
  },
  info5: {
    NL: "De renovatiewerken zijn aanzienlijk en zullen enkele maanden in beslag nemen, waardoor de opening van de winkel nu gepland staat voor februari 2025.",
    FR: "Les travaux de rénovation sont conséquents et prendront plusieurs mois, ce qui nous oblige à reporter l'ouverture de la boutique à février 2025.",
    EN: "The renovation work is extensive and will take several months to complete, so we have rescheduled the opening of the store to February 2025.",
  },
  info6: {
    NL: "In de tussentijd werken wij van thuis uit en blijven wij de laatste ontwikkelingen in de sector nauwgezet volgen. We kijken ernaar uit om deze nieuwigheden binnenkort met u te delen in onze nieuwe winkel.",
    FR: "En attendant, nous travaillons depuis chez nous et suivons de près les dernières nouveautés du secteur. Nous avons hâte de partager ces nouvelles avec vous dans notre nouvelle boutique.",
    EN: "In the meantime, we are working from home and closely following the latest artisanal developments. We look forward to sharing these new products with you soon in our new store.",
  },
  info7: {
    NL: "Heeft u in de tussentijd vragen of wenst u iets te bestellen? Aarzel dan niet om ons te contacteren via e-mail: welcome@tilquin.be.",
    FR: "Si vous avez des questions ou souhaitez passer une commande entre-temps, n'hésitez pas à nous contacter par e-mail : welcome@tilquin.be.",
    EN: "If you have any questions or need to place an order in the meantime, please do not hesitate to contact us via email: welcome@tilquin.be.",
  },
  firstName: {
    NL: "Voornaam",
    FR: "Prénom",
    EN: "First Name",
  },
  lastName: {
    NL: "Achternaam",
    FR: "Nom de famille",
    EN: "Last Name",
  },
  email: {
    NL: "E-mailadres",
    FR: "Adresse électronique",
    EN: "Email Address",
  },
  phone: {
    NL: "Telefoonnummer (optioneel)",
    FR: "Numéro de téléphone (facultatif)",
    EN: "Phone Number (optional)",
  },
  message: {
    NL: "Bericht (optioneel)",
    FR: "Message (facultatif)",
    EN: "Message (optional)",
  },
  submit: {
    NL: "VERZENDEN",
    FR: "ENVOYER",
    EN: "SUBMIT",
  },
  thankYou: {
    NL: "Inzending goed ontvangen. Bedankt dat u met ons verbonden blijft.",
    FR: "Soumission bien reçue. Merci de rester connecté avec nous.",
    EN: "Submission well received. Thank you for staying connected with us.",
  },
  info8: {
    NL: "Inmiddels zijn er, na 163 jaar, uitzonderlijke aanbiedingen op onze huidig adres te ontdekken…",
    FR: "Entre-temps, après 163 ans, des offres exceptionnelles sont à découvrir à notre adresse actuelle…",
    EN: "In the meantime, after 163 years, exceptional offers are to be discovered at our current address…",
  },
  info9: {
    NL: "Zie onder onze pagina UITVERKOOP.",
    FR: "Voir ci-dessous notre page SOLDES.",
    EN: "See below our SALE page.",
  },
  info10: {
    NL: "Tot weldra !",
    FR: "À bientôt !",
    EN: "See you soon!",
  },
};

export default function Announcement({
  language,
  setLanguage,
  page,
  setPage,
  basket,
}) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const isValidInput = (formData) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return (
      formData.firstName && formData.lastName && emailRegex.test(formData.email)
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    push(ref(database, "mailingList/"), {
      data: formData,
      timestamp: new Date().getTime(),
    });
    setIsSubmitted(true);
  };

  return (
    <div className="font-tilquin max-w-4xl mx-auto">
      <Navigation
        language={language}
        setLanguage={setLanguage}
        page={page}
        setPage={setPage}
        basket={basket}
      />
      <img
        src={storeImage}
        alt="store"
        className="mt-5 mb-10 max-h-60 w-full object-cover"
      />
      <div className="flex flex-col md:flex-row text-center sm:text-left px-5">
        <h2 className="text-3xl py-5">{translations.title[language]}</h2>
        <div className="md:pl-16 md:pt-4 flex flex-col space-y-8 leading-8">
          <p>{translations.info1[language]}</p>
          {/* <p>{translations.info2[language]}</p> */}
          <p>{translations.info4[language]}</p>
          <p>{translations.info5[language]}</p>
          <p>{translations.info6[language]}</p>
          <p>{translations.info7[language]}</p>
          <p>{translations.info3[language]}</p>
          <p>{translations.info10[language]}</p>

          <div className="w-full flex flex-col space-y-2">
            {isSubmitted ? (
              <p className="bg-gray-700 text-white px-4 py-2 italic">
                {translations.thankYou[language]}
              </p>
            ) : (
              <form
                onSubmit={handleSubmit}
                className="w-full flex flex-col space-y-4"
              >
                <TextField
                  type="text"
                  name="firstName"
                  label={translations.firstName[language]}
                  value={formData.firstName}
                  onChange={handleChange}
                />
                <TextField
                  type="text"
                  name="lastName"
                  label={translations.lastName[language]}
                  value={formData.lastName}
                  onChange={handleChange}
                />
                <TextField
                  type="email"
                  name="email"
                  label={translations.email[language]}
                  value={formData.email}
                  onChange={handleChange}
                />
                <TextField
                  type="text"
                  name="phone"
                  label={translations.phone[language]}
                  value={formData.phone}
                  onChange={handleChange}
                />
                <TextField
                  type="text"
                  name="message"
                  label={translations.message[language]}
                  value={formData.message}
                  onChange={handleChange}
                  multiline
                />
                <button
                  onClick={handleSubmit}
                  disabled={!isValidInput(formData)}
                  className={`${
                    isValidInput(formData) ? "bg-gray-700" : "bg-gray-300"
                  } text-white px-4 py-2 shadow-lg mx-auto`}
                >
                  {translations.submit[language]}
                </button>
              </form>
            )}
          </div>

          {/* <p>{translations.info8[language]}</p> */}
          {/* <a
            href="/?page=sale"
            className="font-bold underline underline-offset-2"
          >
            {translations.info9[language]}
          </a> */}
        </div>
      </div>
    </div>
  );
}
