import { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const translations = {
  title: {
    NL: "Log in als Beheerder",
    FR: "Connectez-vous en tant qu'Admin",
    EN: "Log in as Admin",
  },
  email: {
    NL: "E-mail",
    FR: "E-mail",
    EN: "Email",
  },
  password: {
    NL: "Wachtwoord",
    FR: "Mot de passe",
    EN: "Password",
  },
  back: {
    NL: "Terug",
    FR: "Retour",
    EN: "Back",
  },
  login: {
    NL: "Log In",
    FR: "Connectez-vous",
    EN: "Log In",
  },
  loading: {
    NL: "Laden...",
    FR: "Chargement...",
    EN: "Loading...",
  },
};

export default function Login({ setPage, language }) {
  const auth = getAuth();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
  }, []);

  async function handleLogin(e) {
    try {
      setError("");
      setLoading(true);

      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
        })
        .catch((error) => {
          setError(error.message);
        });
    } catch (e) {
      console.log(e);
      setError(e.message);
    }

    setLoading(false);
  }

  return (
    <div>
      {loading && <p className="p-5 max-w-xl w-full mx-auto">{translations.loading[language]}</p>}
      {!loading && (
        <div className="w-full p-5 mt-5 flex flex-col gap-y-4 max-w-xl mx-auto">
          <h1 className="text-2xl mb-10">{translations.title[language]}</h1>
          <TextField
            id="email"
            label={translations.email[language]}
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            className="w-full"
          />

          <TextField
            id="password"
            label={translations.password[language]}
            type="password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            autoComplete="current-password"
            className="w-full"
          />

          <div className="flex flex-row justify-between space-x-4 mt-5">
            <button
              className="bg-gray-700 text-white shadow-lg uppercase hover:bg-gray-500 py-2 px-4 w-1/2"
              type="submit"
              disabled={loading}
              onClick={() => {
                setError("");
                setPage(null);
              }}
            >
              {translations.back[language]}
            </button>
            <button
              className="bg-gray-700 text-white shadow-lg uppercase hover:bg-gray-500 py-2 px-4 w-1/2"
              type="submit"
              disabled={loading}
              onClick={() => {
                handleLogin();
                setError("");
              }}
            >
              {translations.login[language]}
            </button>
          </div>
        </div>
      )}
      <p className="p-5 text-red-500 max-w-xl w-full mx-auto">{error}</p>
    </div>
  );
}
